.loaderContainer {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 99999;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.loaderContainer p {
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 30px;
  text-transform: uppercase;
}

.loader {
  width: 58px;
  height: 58px;
  border: 5px solid #3CA970;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
} 