@import url("https://fonts.googleapis.com/css2?family=Allura&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body {
  scroll-behavior: smooth;
}

.container {
  width: auto;
  margin: 0 auto;
  height: 100%;
}

.main-container {
  width: 1040px;
  margin: 0 auto;
  height: 100%;
}

.sendcontainer {
  width: 600px;
  margin: 0 auto;
  height: 100%;
}

.hover-gray-bg {
  background-color: rgba(128, 128, 128, 0.388);
  color: white;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(128, 128, 128, 0.178);
}

::-webkit-scrollbar-thumb:hover {
  background-color: #5555557b;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.react-datepicker-wrapper{
  width: 100% !important;
}