.tooltip {
  position: absolute;
  right: 0;
  top: calc(100% + 5px);
  border: 1px solid #CCCCCC;
  /* padding: 6px 15px; */
  background-color: #fff;
  width: 260px;
  max-height: 250px;
  border-radius: 13px;
  z-index: 999;
  width: 100%;

  box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.1);
  -webkit-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.1);
}

.tooltipArrow::before {
  content: "";
  position: absolute;
  display: block;
  width: 0px;
  right: 3px;
  top: -4px;
  border: 7px solid transparent;
  border-top: 0;
  border-bottom: 7px solid #CCCCCC;
  transform: translate(-100%, -50%);
}