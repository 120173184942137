.tooltip {
  position: absolute;
  left: 100%;
  top: 5px;
  border: 1px solid #CCCCCC;
  padding: 6px 15px;
  background-color: #fff;
  width: 300px;
  min-height: 100px;
  border-radius: 13px;
  z-index: 999;

  box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.1);
  -webkit-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.1);
}

.tooltipLeft::before {
  content: "";
  position: absolute;
  display: block;
  width: 0px;
  left: 0;
  top: 15px;
  border: 7px solid transparent;
  border-left: 0;
  border-right: 7px solid #CCCCCC;
  transform: translate(-100%, -50%);
}