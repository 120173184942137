.container {
  z-index: 10;
}

.active {
  border: 1px solid rgb(60, 169, 112);
  z-index: 11;
}

.resizeDot,
.resizeDotRightCenter {
  border: 1px solid rgb(60, 169, 112);
  border-radius: 50%;
  width: 14px !important;
  height: 14px !important;
  right: -7px !important;
  bottom: -7px !important;
  background-color: #FFF;
}

.resizeDotRightCenter {
  bottom: unset !important;
  top: calc(50% - 7px) !important;
}

.textarea { 
  border: none;
  outline: none;
  width: 100%; 
  height: 100%; 
  resize: none;
  background-color: transparent;
}

.resizing {
  cursor: nwse-resize;
}

.colorTriangle {
  width: 15px;
  height: 15px;
  border-radius: 0;
  top: 0;
  right: 0;
  background: transparent;
  position: absolute;
  overflow: hidden;
}

.colorTriangle .child {
  position: absolute;
  transform: rotate(45deg);
  top: -7px;
  right: -8px;
  width: 15px;
  height: 15px;
}